<div class="installation-header">
  <div class="page-title-container">
    <div class="icon">
      <img [src]="installation?.plugin?.thumbnailUrl" alt="" width=" 30" />
    </div>
    <div class="title">
      <h1>{{ installation?.plugin?.name }}</h1>
      <p class="subtitle">{{ installation?.name }}</p>
    </div>
  </div>
  <div class="actions">
    <ng-content select="app-installation-setup-header-actions"></ng-content>
  </div>
</div>