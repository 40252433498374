import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  filter,
  from,
  map,
  mergeMap,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { AuthService } from '../tapkey-client/services/auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TapkeyHttpInterceptorService implements HttpInterceptor {
  private isRefreshing = false;
  private refreshToken$ = new BehaviorSubject<string | null>(null);

  constructor(
    private readonly authService: AuthService,
    private readonly route: Router
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (req.headers.has('Authorization')) {
      return next.handle(req);
    }

    const authToken$ = from(this.authService.getAuthToken());

    return authToken$.pipe(
      map((token) => {
        return this.addAuthToken(req, token);
      }),
      mergeMap((securedRequest) => {
        return next.handle(securedRequest).pipe(
          catchError((error) => {
            if (error.status === HttpStatusCode.Forbidden) {
              this.route.navigate(['/unauthorized']);
            }

            if (error.status === HttpStatusCode.Unauthorized) {
              return this.handleUnauthorized(req, next);
            }

            return throwError(() => error);
          })
        );
      })
    );
  }

  private addAuthToken(
    req: HttpRequest<unknown>,
    token: string | null
  ): HttpRequest<unknown> {
    return req.clone({
      headers: req.headers.append('Authorization', `Bearer ${token}`),
    });
  }

  private handleUnauthorized(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshToken$.next(null);
      return from(this.authService.refreshAuthToken()).pipe(
        switchMap((newAccessToken) => {
          this.isRefreshing = false;
          this.refreshToken$.next(newAccessToken);
          const securedRequest = this.addAuthToken(req, newAccessToken);
          return next.handle(securedRequest);
        })
      );
    } else {
      return this.refreshToken$.pipe(
        filter((accessToken) => !!accessToken),
        take(1),
        switchMap((accessToken) => {
          return next.handle(this.addAuthToken(req, accessToken));
        })
      );
    }
  }
}
