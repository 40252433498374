<div class="tk-addons-app-container">
  <mat-card>
    <mat-card-header>
      <h3>Communicated Component</h3>
    </mat-card-header>
    <mat-card-content>
      This component receives information from the parent component<br />
      <strong>Auth Token</strong> {{ authToken }} <br />
      <strong>Base Url</strong> {{ baseUrl }}
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="emitChanges()">
        Emit Event
      </button>
    </mat-card-actions>
  </mat-card>
</div>
