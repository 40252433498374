<header class="tk-addons-app-container">
  <nav>
    <ul>
      <li>
        <a mat-button [routerLink]="['/add-ons', 'marketplace']" [routerLinkActive]="['active']">All</a
        >
      </li>
      <li>
        <a
          mat-button
          [routerLink]="['/add-ons', 'installed']"
          [routerLinkActive]="['active']"
          >My Add-Ons</a
        >
      </li>
    </ul>
  </nav>
</header>
<main class="tk-addons-app-container">
  <router-outlet *ngIf="configLoaded; else loadingConfigTemplate" />
</main>

<ng-template #loadingConfigTemplate>
  <mat-progress-bar mode="indeterminate" aria-label="Loading Configuration"></mat-progress-bar>
</ng-template>
