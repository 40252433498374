import { Component, Input } from '@angular/core';
import { Installation } from '@core/dto/installation.dto';

@Component({
  selector: 'app-installation-setup-header',
  templateUrl: './installation-setup-header.component.html',
  styleUrls: ['./installation-setup-header.component.scss'],
})
export class InstallationSetupHeaderComponent {
  @Input()
  installation: Installation | null = null;
}
