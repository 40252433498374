import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-back-navigation',
  templateUrl: './back-navigation.component.html',
  styleUrls: ['./back-navigation.component.scss'],
})
export class BackNavigationComponent {
  @Input()
  label: string;

  @Input()
  navigationParams: string | string[];
}
