import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { CardListComponent } from './layout/card-list/card-list.component';
import { CalendarConfigContainerComponent } from './calendar/calendar-config-container/calendar-config-container.component';
import { CalendarCardComponent } from './calendar/calendar-config-container/calendar-card/calendar-card.component';
import { InstallationSetupLayoutComponent } from './layout/installation-setup-layout/installation-setup-layout.component';
import { InstallationSetupHeaderComponent } from './layout/installation-setup-layout/installation-setup-header/installation-setup-header.component';
import { InstallationSetupContentComponent } from './layout/installation-setup-layout/installation-setup-content/installation-setup-content.component';
import { BannerComponent } from './banner/banner.component';
import { InstallationSetupHeaderActionsComponent } from './layout/installation-setup-layout/installation-setup-header-actions/installation-setup-header-actions.component';
import { BackNavigationComponent } from './back-navigation/back-navigation.component';
import { LoadingBarComponent } from './loading-bar/loading-bar.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [
    CardListComponent,
    CalendarConfigContainerComponent,
    CalendarCardComponent,
    InstallationSetupLayoutComponent,
    InstallationSetupHeaderComponent,
    InstallationSetupHeaderActionsComponent,
    InstallationSetupContentComponent,
    BannerComponent,
    BackNavigationComponent,
    LoadingBarComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule,
    MatButtonModule,
    MatProgressBarModule,
  ],
  exports: [
    CardListComponent,
    InstallationSetupLayoutComponent,
    InstallationSetupHeaderComponent,
    InstallationSetupHeaderActionsComponent,
    InstallationSetupContentComponent,
    BannerComponent,
    BackNavigationComponent,
    LoadingBarComponent,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
  ],
})
export class UICoreModule {}
