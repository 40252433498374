import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-communicated',
  templateUrl: './communicated.component.html',
  styleUrls: ['./communicated.component.scss'],
})
export class CommunicatedComponent implements OnChanges, OnInit {
  @Input()
  authToken = 'Initial';
  @Input()
  baseUrl = 'Initial';

  @Output()
  routeChanged: EventEmitter<{ test: string }> = new EventEmitter();

  changesLog: string[] = [];

  ngOnInit(): void {
    console.log('information', {
      authToken: this.authToken,
      baseUrl: this.baseUrl,
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['authToken']) {
      this.changesLog.push(
        `auth token changed to ${changes['authToken'].currentValue}`
      );
    }
    if (changes['baseUrl']) {
      this.changesLog.push(
        `auth token changed to ${changes['baseUrl'].currentValue}`
      );
    }

    console.log('changes', changes);
  }

  emitChanges(): void {
    this.routeChanged.emit({
      test: 'my value',
    });
  }
}
