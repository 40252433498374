import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthTokenHandler } from './auth-token.handler';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private ownerId$ = new BehaviorSubject<string | null>('');
  private handler?: AuthTokenHandler;
  private ownerId: string;

  constructor() {
    this.ownerId$.next(this.getSelectedOwner());
  }

  getAuthToken() {
    if (this.handler?.getAuthToken) {
      return this.handler.getAuthToken();
    }
    return Promise.reject('No Implementation Supplied for Auth Token');
  }

  refreshAuthToken(): Promise<string> {
    if (this.handler) {
      return this.handler.refreshAuthToken();
    }
    return Promise.reject('No Implementation Supplied for Token Refresh');
  }

  getSelectedOwner() {
    return this.ownerId;
  }

  changeOwnerId(ownerId: string) {
    this.ownerId = ownerId;
    this.ownerId$.next(ownerId);
  }

  getOwnerChanges() {
    return this.ownerId$;
  }

  setExternalAuthHandler(handler: AuthTokenHandler) {
    this.handler = handler;
  }

  isExternalAuthHandlerConfigured() {
    return !!this.handler;
  }
}
