<div class="tk-addons-app-container">
  <mat-card class="add-on">
    <mat-card-header>
      <h3>Pop-up Component</h3>
    </mat-card-header>
    <mat-card-content>
      The Goal with this example is to test Popup type of
      components</mat-card-content
    >
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="openDialog()">
        Open Dialog
      </button>
      <button mat-raised-button color="primary" (click)="openSnackBar()">
        Open Snackbar
      </button>
    </mat-card-actions>
  </mat-card>
</div>
