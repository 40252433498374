import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '@core/tapkey-client/services/auth.service';
import { Router } from '@angular/router';
import { ConfigService } from '@config/config.service';
import { AuthTokenHandler } from '@core/tapkey-client/services/auth-token.handler';

@Component({
  selector: 'addons-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnChanges {
  @Input() ownerId = '';
  @Input() baseComponentUrl = '';
  @Input() authHandler: AuthTokenHandler;

  result$?: BehaviorSubject<string> = new BehaviorSubject<string>('');
  intermediateResult?: string;

  configLoaded = false;

  constructor(
    private readonly authService: AuthService,
    private readonly configService: ConfigService,
    private readonly router: Router
  ) {}

  ngOnInit() {
    // This code allows the switch between owners to happen smothly, without the need to reload the page.
    this.authService.getOwnerChanges().subscribe(() => {
      this.router.navigate(['/add-ons', 'installed']).then();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['ownerId'] && changes['ownerId'].currentValue) {
      this.authService.changeOwnerId(changes['ownerId'].currentValue);
    }

    if (
      changes['baseComponentUrl'] &&
      changes['baseComponentUrl'].currentValue
    ) {
      this.configService.setConfigUrl(changes['baseComponentUrl'].currentValue);
    }

    if (changes['authHandler'] && changes['authHandler'].currentValue) {
      this.authService.setExternalAuthHandler(
        changes['authHandler'].currentValue
      );
    }

    if (!!this.ownerId && !!this.baseComponentUrl && !!this.authHandler) {
      this.configLoaded = true;
    }
  }
}
