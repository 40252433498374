<div class="tk-addons-app-container add-on">
  <mat-card>
    <mat-card-header>
      <h3>Google Calendar Integration</h3>
    </mat-card-header>
    <mat-card-content>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero quisquam
      dignissimos minus ipsum, soluta voluptate dolorum vero! Ipsum, dignissimos
      corporis. Aperiam ipsa maxime sed libero accusantium ut natus nesciunt
      quibusdam?</mat-card-content
    >
    <mat-card-actions
      ><button mat-raised-button color="primary">
        Connect
      </button></mat-card-actions
    >
  </mat-card>
</div>
