import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogExampleComponent } from './dialog-example/dialog-example.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-popups',
  templateUrl: './popups.component.html',
  styleUrls: ['./popups.component.scss'],
})
export class PopupsComponent {
  constructor(
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar
  ) {}

  openDialog(): void {
    this.dialog.open(DialogExampleComponent, {
      width: '80%',
    });
  }

  openSnackBar(): void {
    this.snackBar.open('Snack bar is alive', 'Close snack');
  }
}
