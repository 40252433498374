import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from './config.dto';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, mergeMap, shareReplay, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private readonly configUrlRelative = '/assets/config.json';
  private config$?: Observable<Config>;
  private configUrl$ = new BehaviorSubject<string>('');

  constructor(private readonly http: HttpClient) {}

  private fetchConfiguration(baseUrl: string) {
    return `${baseUrl.trim()}${this.configUrlRelative}`;
  }

  getConfig() {
    if (!this.config$) {
      this.config$ = this.configUrl$.pipe(
        filter((baseUrl) => !!baseUrl),
        mergeMap((baseUrlConfig) => {
          return this.http.get<Config>(this.fetchConfiguration(baseUrlConfig));
        }),
        shareReplay(1),
        take(1)
      );
    }
    return this.config$;
  }

  setConfigUrl(baseUrl: string) {
    this.configUrl$.next(baseUrl);
  }
}
