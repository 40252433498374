import { Routes } from '@angular/router';
import { PopupsComponent } from './popups/popups.component';
import { UnauthorizedComponent } from './error-pages/unauthorized/unauthorized.component';

export const routes: Routes = [
  {
    path: 'add-ons',
    children: [
      {
        path: '',
        redirectTo: 'installed',
        pathMatch: 'full',
      },
      {
        path: 'installed',
        loadChildren: () =>
          import('./installed-add-ons/installed-add-ons.module').then(
            (m) => m.InstalledAddOnsModule
          ),
      },
      {
        path: 'marketplace',
        loadChildren: () =>
          import('./add-ons/add-ons.module').then((m) => m.AddOnsModule),
      },
      { path: '', redirectTo: 'addons', pathMatch: 'full' },
      {
        path: 'popup',
        component: PopupsComponent,
      },
      {
        path: 'unauthorized',
        component: UnauthorizedComponent,
      },
    ],
  },
];
