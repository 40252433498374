<div *ngIf="icon" class="banner-icon">
    <mat-icon aria-hidden="true" [fontIcon]="icon"></mat-icon>
</div>
<div class="banner-header">
    <h2>{{title}}</h2>
</div>
<div class="banner-description">
    <p>{{description}}</p>
</div>
<div class="banner-action" *ngIf="actionText">
    <a mat-flat-button color="primary" [routerLink]="actionRouteArguments">{{actionText}}</a>
</div>