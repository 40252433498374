import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TapkeyHttpInterceptorService } from './http/tapkey-http-interceptor.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  exports: [HttpClientModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TapkeyHttpInterceptorService,
      multi: true,
    },
  ],
})
export class CoreModule {}
