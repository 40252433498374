import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
  @Input()
  title = '';

  @Input()
  description = '';

  @Input()
  icon = '';

  @Input()
  actionText = '';

  @Input()
  actionRouteArguments: string[] = [];
}
